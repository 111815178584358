import Vue from 'vue'
import Router from 'vue-router'
const config = require('@/config')

const jwt = require('jsonwebtoken')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Utenti
        {
          name: 'Gestione Utenti',
          path: 'users',
          component: () => import('@/views/dashboard/users/Users'),
        },
        {
          name: 'Notifications',
          path: 'notifications',
          component: () => import('@/views/dashboard/notifications/Notifications'),
        },
        {
          name: 'Impostazioni',
          path: 'settings',
          component: () => import('@/views/dashboard/sitesettings/SiteSettings'),
        },
        {
          name: 'Photogalleries',
          path: 'photogalleries',
          component: () => import('@/views/dashboard/photogalleries/Photogalleries'),
        },
        // Contenuti
        {
          name: 'Contenuti',
          path: 'contents',
          component: () => import('@/views/dashboard/contents/Contents'),
        },
        // Organi
        {
          name: 'Organi',
          path: 'boards',
          component: () => import('@/views/dashboard/boards/Boards'),
        },
      ],
    },
    {
      name: 'ARCUS - Login',
      path: '/login',
      component: () => import('@/views/Login'),
    },
    // Logout
    {
      name: 'Logout',
      path: '/logout',
      component: () => import('@/views/Logout'),
    },
    {
      path: '*',
      name: 'Errore',
      component: () => import('@/views/NotFound'),
    },
  ],
})

export default router

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/logout']
  var authRequired = !publicPages.includes(to.path)
  document.title = to.name
  for (var publicPage of publicPages) {
    if (to.path.search(publicPage) !== -1) {
      authRequired = false
    }
  }
  var loggedIn = localStorage.getItem(config.authToken)
  if (loggedIn) {
    // console.log('admin_auth_token esiste: ' + loggedIn)
    loggedIn = loggedIn.toString()
    try {
      var payload = jwt.decode(loggedIn)
      if (payload.exp < Math.floor(Date.now() / 1000)) {
        // JWT expired
        localStorage.removeItem(config.authToken)
        // console.log('jwt scaduto')
        return next('/login')
      }
    } catch (error) {
      // Not a valid JWT
      // console.log('jwt non valido')
      localStorage.removeItem(config.authToken)
      return next('/login')
    }
    if (!authRequired) {
      // Pagina pubblica
      // console.log('pagina pubblica, e jwt valido: redirigo su / ?')
    }
  } else {
    // No JWT present in local storage
    // console.log('jwt non presente')
    if (authRequired) {
      return next('/login')
    }
  }
  next()
})
